var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"achievements-list"},[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('a-modal',{attrs:{"title":_vm.modalTitle,"ok-text":_vm.modalOkText,"after-close":function () { return _vm.resetAchievementModel(); },"ok-button-props":{
        props: {
          disabled: invalid
        }
      }},on:{"ok":_vm.handleOkClick},model:{value:(_vm.isAddAchievementModalOpen),callback:function ($$v) {_vm.isAddAchievementModalOpen=$$v},expression:"isAddAchievementModalOpen"}},[_c('text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.achievement,
            'key': 'title',
            'prefix': 'achievement_',
            'validation': 'required',
          }
        }}})],1)]}}])}),_c('a-row',{attrs:{"type":"flex","gutter":[ 16, 16 ]}},[_vm._l((_vm.achievements),function(item,index){return _c('a-col',{key:index,attrs:{"xs":24,"sm":12,"md":24,"lg":12,"xxl":8}},[_c('a-card',{attrs:{"size":"small"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('a-icon',{attrs:{"type":"edit","title":_vm.$t('edit')},on:{"click":function($event){return _vm.setToEdit(item)}}}),_c('a-icon',{staticClass:"text-danger",attrs:{"type":"delete","title":_vm.$t('remove')},on:{"click":function($event){return _vm.removeAchievement(item.id)}}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),_c('a-col',{attrs:{"xs":24,"sm":12,"md":24,"lg":12,"xxl":8}},[_c('add-entity-card',{staticClass:"min-h-24 h-full w-full",attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.isAddAchievementModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('add_achievement'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }