








import AchievementsList from "@/components/Achievements/AchievementsList.vue";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue';

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    PageTitle,
    AchievementsList
  }
})
export default class Achievements extends Mixins(TariffsTagsHelper) {
  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
